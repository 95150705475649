import { request} from "../utils/request";
import {config} from "../utils/constants";
import {getCurrentSiteId, getCurrentUserId} from "../utils/util";

export const getUserEnrollmentByCourseId = (courseId) => {

    return request({
        url: `${config.enrollmentEndpoint}scopes/${getCurrentSiteId()}?filter=r_courseEnrollment_c_courseId eq '${courseId}' and r_userenrollments_userId eq '${getCurrentUserId()}'`,
        method: 'get'
    })
}

export const getActiveCoursesByUserEnrollments = () => {

    return request({
        url: `${config.enrollmentEndpoint}scopes/${getCurrentSiteId()}?filter=r_userenrollments_userId eq '${getCurrentUserId()}' and active eq true and r_courseEnrollment_c_courseId ne '0'&nestedFields=r_courseEnrollment_c_course'`,
        method: 'get'
    })
}

export const getCompletedAssets = async(courseId) => {

    const result = await getUserEnrollmentByCourseId(courseId);
    
    const completedAssetsIds = result?.items?.length > 0 ? result.items[0].completedAssetIds : [];  

    return completedAssetsIds
}

export const updateCompletedAssets = (userEnrollmentId, completedAssetIds, assetId) => {

    return request({
        url: `${config.enrollmentEndpoint}/${userEnrollmentId}`,
        method: 'PATCH',
        data: {
            completedAssetIds: completedAssetIds += ',' + assetId,
        }
    });
}

