import "../index.css";
import ClayLayout from "@clayui/layout";
import { enrollToCourse } from "../services/course";
import { isSignedIn, getShortText } from "../utils/util";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Liferay = window.Liferay;

const getAudience = (audienceArray) => {
  let audience = '';

  if(audienceArray) {
    audience = audienceArray[0].name;

    if(audienceArray.length > 1) {
      audience += '+' + (audienceArray.length-1);
    }
  }
  return audience;
}



const CoursesList = ({ paging, page, pageSize, durationFormat }) => {
  const [courses, setCourses] = useState(null);
  const [userEnrollments, setUserEnrollments] = useState(null);

  const {t, i18n: {changeLanguage, language}} = useTranslation();

  const handleResgisterButton = (courseId) => {
    if(!isSignedIn()) {
      return;
    }
  
    if(!userEnrollments?.includes(courseId)) {
      return (
      <>
          <button
            className="btn btn-sm btn-secondary align-self-start card-tag"
            type="button"
            onClick={() => enrollToCourse(courseId)}
          >
            {t('register')}
          </button>
      </>
      )
    }
  }

  useEffect(() => {
    Liferay.Util.fetch(
      `/o/c/courses/scopes/${Liferay.ThemeDisplay.getScopeGroupId()}?page=1&pageSize=3&fields=audience,description,durationMinutes,firstLesson,id,level,title&sort=position:asc`
    )
    .then((response) => response.json())
    .then((response) => {
        setCourses(response.items);
    });

    if(isSignedIn()) {
      Liferay.Util.fetch(
        `/o/c/enrollments/scopes/${Liferay.ThemeDisplay.getScopeGroupId()}?fields=r_courseEnrollment_c_courseId&filter=r_userenrollments_userId eq '${Liferay.ThemeDisplay.getUserId()}'`
      )
      .then((response) => response.json())
      .then((response) => {
        if(response.items) {
          setUserEnrollments(response.items.map(item => item.r_courseEnrollment_c_courseId));
        }
      });
    }
  }, []);

  return (
    <ClayLayout.ContainerFluid view>
      <ClayLayout.Row justify="start">
        {courses && courses.length > 0 && (
          <>
            {courses.map((course, index) => {
              return (
                <ClayLayout.Col
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  key={index}
                  className="d-flex course-layout-col"
                >
                  <a href={`/l/${course.firstLesson}`} id="course-container-link">
                    <div className="course-container d-flex learn-education__landing-card">
                      <div className="course-content-card d-flex flex-column justify-content-between">
                        <h4 className="title">{course.title}</h4>
                        <div className="description">
                          {getShortText(course.description, 150)}
                        </div>
                        <div
                          className="d-flex information learn-education"
                          key={course.id}
                        >
                          <div className="card-tag card-tag__persona">
                            <p></p>
                            {getAudience(course.audience)}
                          </div>
                          <div
                            className={`card-tag card-tag__expertise-${course.level.name.toLowerCase()}`}
                          >
                            <p>{course.level.name}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </ClayLayout.Col>
              );
            })}
          </>
        )}
      </ClayLayout.Row>
    </ClayLayout.ContainerFluid>
  );
};

export default CoursesList;