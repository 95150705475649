import ClayLayout from "@clayui/layout";
const UserDashboardBadgeCard = ({ userBadges }) => {
  return (
    <ClayLayout.ContainerFluid>
      <ClayLayout.Row className="gap-3">
        {userBadges.map((badge, index) => (
          <ClayLayout.Col className="d-flex flex-column p-0" key={index}>
            <div className="badge-card">
              <div>
                <div className="badge-img-container mb-3">
                  <img alt={badge.title} src={badge.image}></img>
                </div>
                <h3>{badge.title}</h3>
              </div>
              <p>{badge.year}</p>
            </div>
          </ClayLayout.Col>
        ))}
      </ClayLayout.Row>
    </ClayLayout.ContainerFluid>
  );
};

export default UserDashboardBadgeCard;
