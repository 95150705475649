import { getShortText } from "../../utils/util";
import ClayProgressBar from "@clayui/progress-bar";
import ClayIcon from "@clayui/icon";

const UserDashboardCourseCard = ({ userEnrollments }) => {
  return (
    <div className="mb-6 section-cards">
      {userEnrollments.map((item) => {
        return (
          <a
            href={`/l/${item.firstLesson}`}
            key={item.id}
            className="section-card"
          >
            <div className="d-flex flex-column h-100 justify-content-between">
              <div>
                <h3>{item.title}</h3>
                <p>{getShortText(item.description)}</p>
              </div>
              {item.courseComplete ? (
                <p className="align-self-start completed-tag">
                  Completed
                  <ClayIcon className="ml-2" symbol="check" />
                </p>
              ) : (
                <ClayProgressBar value={item.courseProgress} className="mt-3" />
              )}
            </div>
          </a>
        );
      })}
    </div>
  );
};

export default UserDashboardCourseCard;
