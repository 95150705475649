import "../index.css";
import ClayLayout from "@clayui/layout";
import { getShortText } from "../utils/util";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Liferay = window.Liferay;

const getAudience = (audienceArray) => {
    let audience = '';

    audienceArray.forEach((persona) => {
      audience += persona.name + ', '
    })
    
    return audience.slice(0, -2);
}


const CoursePage = () => {
    const [courses, setCourses] = useState(null);
  
    const {t, i18n: {changeLanguage, language}} = useTranslation();
  
    useEffect(() => {
      Liferay.Util.fetch(
        `/o/c/courses/scopes/${Liferay.ThemeDisplay.getScopeGroupId()}?pageSize=-1&fields=audience,description,durationMinutes,firstLesson,id,level,title&sort=position:asc`
      )
      .then((response) => response.json())
      .then((response) => {
          setCourses(response.items);
      });
    }, []);
  
    return (
      <div>
        <div className="container-cards-page">
          {courses && courses.length > 0 && (
            <>
              {courses.map((course, index) => {
                return (
                  <ClayLayout.Col className="card-course">
                    <div className="tag-course">
                        <p>{t('course')}</p>
                    </div>
                    <a href={`/l/${course.firstLesson}`} id="course-container-link">
                      <div className="course-container d-flex learn-education__landing-card">
                        <div className="course-content-card d-flex flex-column justify-content-between">
                          <h4 className="title">{course.title}</h4>
                          <div className="description">
                            {getShortText(course.description, 150)}
                          </div>
                          <div
                            className="d-flex information learn-education"
                            key={course.id}
                          >
                            <div className="card-tag card-tag__persona">
                              <p></p>
                              <span>{getAudience(course.audience)}</span>
                            </div>
                            <div
                              className={`card-tag card-tag__expertise-${course.level.name.toLowerCase()}`}
                            >
                              <p>{course.level.name}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </ClayLayout.Col>
                );
              })}
            </>
          )}
        </div>
      </div>
    );
  };

export default CoursePage