/* global Liferay */

import React, {createElement, Fragment} from 'react';
import { createRoot } from 'react-dom/client';
import './index.css'

import CourseView from "./components/CourseView";
import {ClayIconSpriteContext} from '@clayui/icon';
import ModuleView from "./components/ModuleView";
import LessonView from "./components/LessonView";
import Enroll from "./components/Enroll";
import LMSMenu from "./components/LMSMenu";
import ExerciseView from "./components/Quiz/ExerciseView";

import './i18n'
import DeleteObjects from "./components/DeleteObjects";
import CreateObjects from "./components/CreateObjects";
import {getChildByAttribute, loadStyle} from "./utils/util";
import UserProgressReport from "./components/UserProgressReport";
import Duration from "./components/Duration";
import CourseFirstLesson from "./components/CourseFirstLesson";
import NavigationMenu from "./components/NavigationMenu";
import ArticleNavigation from "./components/ArticleNavigation";

import './utils/lms-global';
import LessonNavigation from "./components/LessonNavigation";
import LearningPathSteps from "./components/LearningPathSteps"
import CoursesList from "./components/CoursesList";
import LearningPathsList from './components/LearningPathsList';
import CourseBanner from './components/CourseBanner'
import CoursePage from './components/CoursePage';
import UserDashboardView from './components/UserDashboard/UserDashboardView'; 
import LearningPathPage from './components/LearningPathPage';

const COURSE_ENROLL_WATCH_ATTRIBUTES = ['course-id','learning-path-id'];
const COURSE_VIEW_WATCH_ATTRIBUTES = ['course-id'];
const EXERCISE_VIEW_WATCH_ATTRIBUTES = ['exercise-id'];
const LESSON_VIEW_WATCH_ATTRIBUTES = ['lesson-id'];
const MODULE_VIEW_WATCH_ATTRIBUTES = ['module-id'];
const DURATION_VIEW_WATCH_ATTRIBUTES = ['asset-id','asset-type'];
const LESSON_NAVIGATION_WATCH_ATTRIBUTES = [];
const DASHBOARD_VIEW_WATCH_ATTRIBUTES = ['dashboard-id'];

const ELEMENT_ID_COURSE_ENROLL = 'liferay-lms-course-enroll';
const ELEMENT_ID_LEARNING_PATH_ENROLL = 'liferay-lms-learning-path-enroll';
const ELEMENT_ID_COURSE_VIEW = 'liferay-lms-course-view';
const ELEMENT_ID_EXERCISE_VIEW = 'liferay-lms-exercise-view';
const ELEMENT_ID_LESSON_VIEW = 'liferay-lms-lesson-view';
const ELEMENT_ID_LMS_MENU = 'liferay-lms-menu';
const ELEMENT_ID_MODULE_VIEW = 'liferay-lms-module-view';
const ELEMENT_ID_PROGRESS_REPORT = 'liferay-lms-user-progress-report';
const ELEMENT_ID_DURATION = 'liferay-lms-duration';
const ELEMENT_ID_LESSON_ONE = 'liferay-lms-course-lesson-one-link';
const ELEMENT_ID_NAVIGATION_MENU = 'liferay-lms-navigation-menu';
const ELEMENT_ID_ARTICLE_NAVIGATION_MENU = 'liferay-lms-article-navigation-menu';
const ELEMENT_ID_LESSON_NAVIGATION = 'liferay-lms-lesson-navigation';
const ELEMENT_ID_LEARNING_PATH_STEPS = 'liferay-lms-learning-path-steps';
const ELEMENT_ID_COURSES_LIST = 'liferay-lms-courses-list';
const ELEMENT_ID_LEARN_PATHS_LIST = 'liferay-lms-learn-paths-list';
const ELEMENT_ID_LMS_COURSE_BANNER = 'liferay-lms-course-banner';
const ELEMENT_ID_LMS_COURSE_PAGE = 'liferay-lms-course-page';
const ELEMENT_ID_DASHBOARD_VIEW = 'liferay-lms-dashboard-view';
const ELEMENT_ID_LMS_LEARNING_PATH_PAGE = 'liferay-lms-learning-path-page';


const ELEMENT_ID_DELETE_OBJECTS = 'liferay-lms-delete-objects';
const ELEMENT_ID_CREATE_OBJECTS = 'liferay-lms-create-objects';

class CoursePageComponent extends HTMLElement {
    static get observedAttributes() {
        return MODULE_VIEW_WATCH_ATTRIBUTES;
    }

    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }

    renderComponent() {
        if ( this.root) {
            this.root.render(
                <ClayIconSpriteContext.Provider value={Liferay.Icons.spritemap}>
                    <CoursePage></CoursePage>
                </ClayIconSpriteContext.Provider>
            );
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class LearningPathPageComponent extends HTMLElement{
    static get observedAttributes() {
        return MODULE_VIEW_WATCH_ATTRIBUTES;
    }
    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }

    renderComponent() {
        if ( this.root) {
            this.root.render(
                <ClayIconSpriteContext.Provider value={Liferay.Icons.spritemap}>
                    <LearningPathPage></LearningPathPage>
                </ClayIconSpriteContext.Provider>
            );
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class CourseViewComponent extends HTMLElement {
    static get observedAttributes() {
        return COURSE_VIEW_WATCH_ATTRIBUTES;
    }

    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (COURSE_VIEW_WATCH_ATTRIBUTES.includes(name) && oldValue !== newValue) {
            this.renderComponent(newValue);
        }
    }

    renderComponent(moduleId = this.getAttribute('module-id')) {
        if ( this.root) {
            let height = this.getAttribute('height') || '100vh';
            let videoHeight = this.getAttribute('video-height') || '500px'
            let videoWidth = this.getAttribute('video-width') || '100%'
            let courseId = this.getAttribute('course-id');
            this.root.render(
                <ClayIconSpriteContext.Provider key={courseId} value={Liferay.Icons.spritemap}>
                    <CourseView key={courseId} height={height} courseId={courseId} videoHeight={videoHeight} videoWidth={videoWidth} />
                </ClayIconSpriteContext.Provider>);
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class ModuleViewComponent extends HTMLElement {
    static get observedAttributes() {
        return MODULE_VIEW_WATCH_ATTRIBUTES;
    }

    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }

    attributeChangedCallback(name, oldValue, newValue) {

        let moduleId = this.getAttribute('module-id');

        if (MODULE_VIEW_WATCH_ATTRIBUTES.includes(name)  && oldValue !== newValue){
            if (name === "module-id") {
                this.renderComponent(newValue,moduleId);
            }
        }

    }
    renderComponent(moduleId = this.getAttribute('module-id')) {
        if ( this.root) {
            let height = this.getAttribute('height') || '100vh';
            let videoHeight = this.getAttribute('video-height') || '500px'
            let videoWidth = this.getAttribute('video-width') || '100%'

            this.root.render(
                (<ClayIconSpriteContext.Provider key={moduleId} value={Liferay.Icons.spritemap}>
                    <ModuleView moduleId={moduleId} key={`${moduleId}`} height={height} videoHeight={videoHeight} videoWidth={videoWidth} />
                </ClayIconSpriteContext.Provider>)
            );
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class LessonViewComponent extends HTMLElement {
    static get observedAttributes() {
        return LESSON_VIEW_WATCH_ATTRIBUTES;
    }

    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (LESSON_VIEW_WATCH_ATTRIBUTES.includes(name) && oldValue !== newValue) {
            this.renderComponent(newValue);
        }
    }

    renderComponent(lessonId = this.getAttribute('lesson-id')) {
        if ( this.root) {
            let height = this.getAttribute('height') || '100vh';
            let videoHeight = this.getAttribute('video-height') || '500px'
            let videoWidth = this.getAttribute('video-width') || '100%'
            this.root.render(
                <ClayIconSpriteContext.Provider key={lessonId} value={Liferay.Icons.spritemap}>
                <LessonView lessonId={lessonId} key={lessonId} height={height} videoHeight={videoHeight} videoWidth={videoWidth}/>
                </ClayIconSpriteContext.Provider>);
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class ExerciseViewComponent extends HTMLElement {
    static get observedAttributes() {
        return COURSE_ENROLL_WATCH_ATTRIBUTES;
    }

    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (EXERCISE_VIEW_WATCH_ATTRIBUTES.includes(name) && oldValue !== newValue) {
            this.renderComponent(newValue);
        }
    }

    renderComponent(
        exerciseId = this.getAttribute('exercise-id'),
        height=this.getAttribute('height'),
        enableBackButton = this.getAttribute('enable-back-button') || false,
        educationPageUrl = this.getAttribute('education-page-url'),
        badgePageUrl = this.getAttribute('badge-page-url'),) {
        if ( this.root) {
            this.root.render(
                <ClayIconSpriteContext.Provider key={exerciseId} value={Liferay.Icons.spritemap}>
                    <ExerciseView enableBackButton={enableBackButton} educationPageUrl={educationPageUrl} badgePageUrl={badgePageUrl}  exerciseId={exerciseId} height={height} key={exerciseId} />
                </ClayIconSpriteContext.Provider>);
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class CoursesListComponent extends HTMLElement {
    static get observedAttributes() {

    }

    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }

    attributeChangedCallback(name, oldValue, newValue) {

    }

    renderComponent(
        page = this.getAttribute('page') || 1,
        pageSize=this.getAttribute('page-size') || 3,
        paging = this.getAttribute('paging') || false,
        durationFormat = this.getAttribute("duration-format")) {
        if ( this.root) {
            this.root.render(
                <ClayIconSpriteContext.Provider value={Liferay.Icons.spritemap}>
                    <CoursesList durationFormat={durationFormat} pageSize={pageSize} paging={paging} page={page}></CoursesList>
                </ClayIconSpriteContext.Provider>);
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class LearningPathsListComponent extends HTMLElement {
    static get observedAttributes() {

    }

    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }

    attributeChangedCallback(name, oldValue, newValue) {

    }

    renderComponent(
        page = this.getAttribute('page') || 1,
        pageSize=this.getAttribute('page-size') || 3,
        paging = this.getAttribute('paging') || false,
        durationFormat = this.getAttribute("duration-format")) {
        if ( this.root) {
            this.root.render(
                <ClayIconSpriteContext.Provider value={Liferay.Icons.spritemap}>
                    <LearningPathsList durationFormat={durationFormat} pageSize={pageSize} paging={paging} page={page}></LearningPathsList>
                </ClayIconSpriteContext.Provider>);
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class LearningPathEnrollComponent extends HTMLElement {
    static get observedAttributes() {
        return COURSE_ENROLL_WATCH_ATTRIBUTES;
    }

    constructor() {
        super();

        this.shadow = this.attachShadow({ mode: 'open' });


        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this.shadow);
            this.renderComponent();
        }
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (COURSE_ENROLL_WATCH_ATTRIBUTES.includes(name) && oldValue !== newValue) {
            this.renderComponent(newValue);
        }
    }

    renderComponent(learningPathId = this.getAttribute('asset-id'),viewMode=this.getAttribute('view-mode')) {
        if ( this.root) {

            const childrenArray = Array.from(this.childNodes);

            const children = childrenArray.map((child, index) => {
                return <Fragment key={index}>
                    {createElement('div', { dangerouslySetInnerHTML: { __html: child.outerHTML } })}
                </Fragment>;
            });

            const slottedAssetId = getChildByAttribute(this,"data-slot-name","asset-id");

            learningPathId =slottedAssetId=== null || slottedAssetId.textContent === null || slottedAssetId.textContent.length === 0 ? learningPathId : slottedAssetId.textContent;

            this.root.render(
                <ClayIconSpriteContext.Provider key={learningPathId} value={Liferay.Icons.spritemap}>
                    <Enroll children={viewMode != "view"?children: null} learningPathId={learningPathId} key={learningPathId} />
                </ClayIconSpriteContext.Provider>);
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class CourseEnrollComponent extends HTMLElement {
    static get observedAttributes() {
        return COURSE_ENROLL_WATCH_ATTRIBUTES;
    }

    constructor() {
        super();

        this.shadow = this.attachShadow({ mode: 'open' });


        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this.shadow);
            this.renderComponent();
        }
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (COURSE_ENROLL_WATCH_ATTRIBUTES.includes(name) && oldValue !== newValue) {
            this.renderComponent(newValue);
        }
    }

    renderComponent(courseId = this.getAttribute('asset-id'),viewMode=this.getAttribute('view-mode')) {
        if ( this.root) {

            const childrenArray = Array.from(this.childNodes);

            const children = childrenArray.map((child, index) => {
                return <Fragment key={index}>
                    {createElement('div', { dangerouslySetInnerHTML: { __html: child.outerHTML } })}
                </Fragment>;
            });

            const slottedAssetId = getChildByAttribute(this,"data-slot-name","asset-id");

            courseId = slottedAssetId=== null || slottedAssetId.textContent === null || slottedAssetId.textContent.length === 0 ? courseId : slottedAssetId.textContent;

            this.root.render(
                <ClayIconSpriteContext.Provider key={courseId} value={Liferay.Icons.spritemap}>
                    <Enroll children={viewMode != "view"?children: null} courseId={courseId} key={courseId} />
                </ClayIconSpriteContext.Provider>);
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class UserProgressReportComponent extends HTMLElement {
    static get observedAttributes() {
        return COURSE_ENROLL_WATCH_ATTRIBUTES;
    }

    constructor() {
        super();

        this.shadow = this.attachShadow({ mode: 'open' });


        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this.shadow);

            this.renderComponent();

        }
    }

    updateShadowDOM(styles) {
        // Create a new CSSStyleSheet
        const styleSheet = new CSSStyleSheet();
        styleSheet.replaceSync(styles);

        // Apply styles to Shadow DOM
        this.shadow.adoptedStyleSheets = [styleSheet];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (COURSE_ENROLL_WATCH_ATTRIBUTES.includes(name) && oldValue !== newValue) {
            this.renderComponent(newValue);
        }
    }

    renderComponent(
        assetId = this.getAttribute('asset-id'),
        assetProgress = this.getAttribute('asset-progress'),
        assetType = this.getAttribute('asset-type'),
        viewMode=this.getAttribute('view-mode')
    ) {
        if ( this.root) {

            const childrenArray = Array.from(this.childNodes);

            const children = childrenArray.map((child, index) => {
                return <Fragment key={index}>
                    {createElement('div', { dangerouslySetInnerHTML: { __html: child.outerHTML } })}
                </Fragment>;
            });

            const slottedAssetId = getChildByAttribute(this,"data-slot-name","asset-id");
            const slottedAssetType = getChildByAttribute(this,"data-slot-name","asset-type");
            const slottedProgress = getChildByAttribute(this,"data-slot-name","asset-progress");

            assetId = slottedAssetId === null || slottedAssetId.textContent === null || slottedAssetId.textContent.length === 0 ? assetId : slottedAssetId.textContent;
            assetProgress =  slottedProgress === null || slottedProgress.textContent === null || slottedProgress.textContent.length === 0 ? assetProgress : slottedProgress.textContent;
            assetType =  slottedAssetType === null || slottedAssetType.textContent === null || slottedAssetType.textContent.length === 0 ? assetType : slottedAssetType.textContent;

            this.root.render(
                <ClayIconSpriteContext.Provider key={assetId} value={Liferay.Icons.spritemap}>
                    <UserProgressReport
                                        assetId={assetId}
                                        assetProgress={assetProgress}
                                        assetType={assetType}
                                        key={assetId}
                                        viewMode={viewMode}
                                        children={children}
                    />
                </ClayIconSpriteContext.Provider>);
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class DurationComponent extends HTMLElement {
    static get observedAttributes() {
        return DURATION_VIEW_WATCH_ATTRIBUTES;
    }

    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }


    attributeChangedCallback(name, oldValue, newValue) {
        if (COURSE_ENROLL_WATCH_ATTRIBUTES.includes(name) && oldValue !== newValue) {
            this.renderComponent(newValue);
        }
    }

    renderComponent(
        assetId = this.getAttribute('asset-id'),
        assetType = this.getAttribute('asset-type'),
        format = this.getAttribute('format'),
    ) {
        if ( this.root) {

            this.root.render(
                <ClayIconSpriteContext.Provider key={assetId} value={Liferay.Icons.spritemap}>
                    <Duration
                        assetId={assetId}
                        assetType={assetType}
                        format={format}
                        key={assetId}
                    />
                </ClayIconSpriteContext.Provider>);
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class CourseLessonOneLinkComponent extends HTMLElement {
    static get observedAttributes() {
        return DURATION_VIEW_WATCH_ATTRIBUTES;
    }

    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }


    attributeChangedCallback(name, oldValue, newValue) {
        if (COURSE_ENROLL_WATCH_ATTRIBUTES.includes(name) && oldValue !== newValue) {
            this.renderComponent(newValue);
        }
    }

    renderComponent(
        assetId = this.getAttribute('asset-id'),
        linkTitle = this.getAttribute('link-title')
    ) {
        if ( this.root) {

            this.root.render(
                <ClayIconSpriteContext.Provider key={assetId} value={Liferay.Icons.spritemap}>
                    <CourseFirstLesson
                        assetId={assetId}
                        linkTitle={linkTitle}
                        key={assetId}
                    />
                </ClayIconSpriteContext.Provider>);
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class NavigationMenuComponent extends HTMLElement {
    static get observedAttributes() {
        return COURSE_ENROLL_WATCH_ATTRIBUTES;
    }

    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }


    attributeChangedCallback(name, oldValue, newValue) {
        if (COURSE_ENROLL_WATCH_ATTRIBUTES.includes(name) && oldValue !== newValue) {
            this.renderComponent(newValue);
        }
    }

    renderComponent(
        assetId = this.getAttribute('asset-id'),
        assetType = this.getAttribute('asset-type'),
        navigationMenuType=this.getAttribute('navigation-menu-type'),
        moduleTextLabel=this.getAttribute('module-label')
    ) {
        if ( this.root) {

            this.root.render(
                <ClayIconSpriteContext.Provider key={assetId} value={Liferay.Icons.spritemap}>
                    <NavigationMenu
                        assetId={assetId}
                        assetType={assetType}
                        key={assetId}
                        moduleTextLabel={moduleTextLabel}
                        navigationMenuType={navigationMenuType}
                    />
                </ClayIconSpriteContext.Provider>);
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class ArticleNavigationComponent extends HTMLElement {
    static get observedAttributes() {
        return COURSE_ENROLL_WATCH_ATTRIBUTES;
    }

    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }


    attributeChangedCallback(name, oldValue, newValue) {
        if (COURSE_ENROLL_WATCH_ATTRIBUTES.includes(name) && oldValue !== newValue) {
            this.renderComponent(newValue);
        }
    }

    renderComponent(
        selector = this.getAttribute('selector'),
        containerId = this.getAttribute('container-id'),
    ) {
        if ( this.root) {

            this.root.render(
                <ClayIconSpriteContext.Provider value={Liferay.Icons.spritemap}>
                    <ArticleNavigation
                        selector={selector}
                        containerId={containerId}
                    />
                </ClayIconSpriteContext.Provider>);
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class LessonNavigationComponent extends HTMLElement {
    static get observedAttributes() {
        return LESSON_NAVIGATION_WATCH_ATTRIBUTES;
    }

    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }


    attributeChangedCallback(name, oldValue, newValue) {
        if (LESSON_NAVIGATION_WATCH_ATTRIBUTES.includes(name) && oldValue !== newValue) {
            this.renderComponent(newValue);
        }
    }

    renderComponent(
        upNextLabel = this.getAttribute("up-next-label"),
        linkPrefix = this.getAttribute("link-prefix")) {
        if ( this.root) {

            this.root.render(
                <ClayIconSpriteContext.Provider value={Liferay.Icons.spritemap}>
                    <LessonNavigation upNextLabel={upNextLabel} linkPrefix={linkPrefix} />
                </ClayIconSpriteContext.Provider>);
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class MenuComponent extends HTMLElement {

    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }


    renderComponent() {
        if ( this.root) {
            this.root.render(
                <ClayIconSpriteContext.Provider  value={Liferay.Icons.spritemap}>
                    <LMSMenu/>
                </ClayIconSpriteContext.Provider>);
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class DeleteObjectsComponent extends HTMLElement {

    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }


    renderComponent() {
        if ( this.root) {
            this.root.render(<DeleteObjects/>);
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class CreateObjectsComponent extends HTMLElement {

    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }


    renderComponent() {
        if ( this.root) {
            this.root.render(<CreateObjects/>);
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}
class CourseBannerComponent extends HTMLElement {
    static get observedAttributes() {
        return COURSE_VIEW_WATCH_ATTRIBUTES;
    }

    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (COURSE_VIEW_WATCH_ATTRIBUTES.includes(name) && oldValue !== newValue) {
            this.renderComponent(newValue);
        }
    }

    renderComponent(
        assetId = this.getAttribute("assetId"), assetType = this.getAttribute("assetType"))
         {
        if (this.root) {
            this.root.render(
                <ClayIconSpriteContext.Provider value={Liferay.Icons.spritemap}>
                    <CourseBanner assetId={assetId} assetType={assetType}></CourseBanner>
                </ClayIconSpriteContext.Provider>);
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

class LearningPathStepsComponentListComponent extends HTMLElement {
    static get observedAttributes() {
      return MODULE_VIEW_WATCH_ATTRIBUTES;
    }
  
    constructor() {
      super();
      this.root = null;
    }
  
    connectedCallback() {
      if (!this.root) {
        this.root = createRoot(this);
      }
      this.renderComponent();
    }
  
    renderComponent(learningPathId = this.getAttribute("asset-id")) {
      if (this.root) {
        this.root.render(
          <ClayIconSpriteContext.Provider key={learningPathId} value={Liferay.Icons.spritemap}>
            <LearningPathSteps key={learningPathId} learningPathId={learningPathId} />
          </ClayIconSpriteContext.Provider>
        );
      }
    }
  
    disconnectedCallback() {
      if (this.root) {
        this.root.unmount();
        this.root = null;
      }
    }
  }

  class UserDashboardViewComponent extends HTMLElement {
    static get observedAttributes() {
        return DASHBOARD_VIEW_WATCH_ATTRIBUTES;
    }

    constructor() {
        super();
        this.root = null;
    }

    connectedCallback() {
        if (!this.root) {
            this.root = createRoot(this);
        }
        this.renderComponent();
    }

    attributeChangedCallback(name, oldValue, newValue) {

        let moduleId = this.getAttribute('module-id');

        if (DASHBOARD_VIEW_WATCH_ATTRIBUTES.includes(name)  && oldValue !== newValue){
            if (name === "module-id") {
                this.renderComponent(newValue,moduleId);
            }
        }

    }
    renderComponent(dashboardId = this.getAttribute('module-id')) {
        if ( this.root) {

            this.root.render(
                (<ClayIconSpriteContext.Provider key={dashboardId} value={Liferay.Icons.spritemap}>
                    <UserDashboardView moduleId={dashboardId} key={`${dashboardId}`} />
                </ClayIconSpriteContext.Provider>)
            );
        }
    }

    disconnectedCallback() {
        if (this.root) {
            this.root.unmount();
            this.root = null;
        }
    }
}

if (!customElements.get(ELEMENT_ID_LMS_COURSE_PAGE)) {

    customElements.define(ELEMENT_ID_LMS_COURSE_PAGE, CoursePageComponent);
}

if (!customElements.get(ELEMENT_ID_LMS_LEARNING_PATH_PAGE)) {

    customElements.define(ELEMENT_ID_LMS_LEARNING_PATH_PAGE, LearningPathPageComponent);
}

if (!customElements.get(ELEMENT_ID_COURSE_VIEW)) {

    customElements.define(ELEMENT_ID_COURSE_VIEW, CourseViewComponent);
}

if (!customElements.get(ELEMENT_ID_MODULE_VIEW)) {

    customElements.define(ELEMENT_ID_MODULE_VIEW, ModuleViewComponent);
}

if (!customElements.get(ELEMENT_ID_LESSON_VIEW)) {

    customElements.define(ELEMENT_ID_LESSON_VIEW, LessonViewComponent);

}

if (!customElements.get(ELEMENT_ID_COURSE_ENROLL)) {

    customElements.define(ELEMENT_ID_COURSE_ENROLL, CourseEnrollComponent);

}

if (!customElements.get(ELEMENT_ID_LEARNING_PATH_ENROLL)) {

    customElements.define(ELEMENT_ID_LEARNING_PATH_ENROLL, LearningPathEnrollComponent);
}

if (!customElements.get(ELEMENT_ID_LMS_MENU)) {

    customElements.define(ELEMENT_ID_LMS_MENU, MenuComponent);

}

if (!customElements.get(ELEMENT_ID_EXERCISE_VIEW)) {

    customElements.define(ELEMENT_ID_EXERCISE_VIEW, ExerciseViewComponent);

}

if (!customElements.get(ELEMENT_ID_PROGRESS_REPORT)) {

    customElements.define(ELEMENT_ID_PROGRESS_REPORT, UserProgressReportComponent);
}

if (!customElements.get(ELEMENT_ID_DURATION)) {

    customElements.define(ELEMENT_ID_DURATION, DurationComponent);
}

if (!customElements.get(ELEMENT_ID_LESSON_ONE)) {

    customElements.define(ELEMENT_ID_LESSON_ONE, CourseLessonOneLinkComponent);
}

if (!customElements.get(ELEMENT_ID_NAVIGATION_MENU)) {

    customElements.define(ELEMENT_ID_NAVIGATION_MENU, NavigationMenuComponent);
}

if (!customElements.get(ELEMENT_ID_ARTICLE_NAVIGATION_MENU)) {

    customElements.define(ELEMENT_ID_ARTICLE_NAVIGATION_MENU, ArticleNavigationComponent);
}

if (!customElements.get(ELEMENT_ID_LESSON_NAVIGATION)) {

    customElements.define(ELEMENT_ID_LESSON_NAVIGATION, LessonNavigationComponent);
}

if (!customElements.get(ELEMENT_ID_DELETE_OBJECTS)) {

    customElements.define(ELEMENT_ID_DELETE_OBJECTS, DeleteObjectsComponent);
}

if (!customElements.get(ELEMENT_ID_CREATE_OBJECTS)) {

    customElements.define(ELEMENT_ID_CREATE_OBJECTS, CreateObjectsComponent);
}

if (!customElements.get(ELEMENT_ID_LEARNING_PATH_STEPS)) {
    
    customElements.define(ELEMENT_ID_LEARNING_PATH_STEPS, LearningPathStepsComponentListComponent);
}

if (!customElements.get(ELEMENT_ID_COURSES_LIST)) {

    customElements.define(ELEMENT_ID_COURSES_LIST, CoursesListComponent);
}

if (!customElements.get(ELEMENT_ID_LEARN_PATHS_LIST)) {

    customElements.define(ELEMENT_ID_LEARN_PATHS_LIST, LearningPathsListComponent);
}

if (!customElements.get(ELEMENT_ID_LMS_COURSE_BANNER)) {

    customElements.define(ELEMENT_ID_LMS_COURSE_BANNER, CourseBannerComponent);
}

if (!customElements.get(ELEMENT_ID_DASHBOARD_VIEW)) {

    customElements.define(ELEMENT_ID_DASHBOARD_VIEW, UserDashboardViewComponent);
}