/* global Liferay */
import "../index.css";
import ClayLayout from "@clayui/layout";
import { forwardRef, useEffect, useState } from "react";
import { convertMinutesToDuration, getPersonaStringList } from "../utils/util";

const Liferay = window.Liferay;

const CourseBanner = forwardRef((props, ref) => {
  const [course, setCourse] = useState({});

  const { assetId, assetType } = props;

  useEffect(() => {
    if (assetId && assetType) {
      Liferay.Util.fetch(
        `/o/c/courses/scopes/${Liferay.ThemeDisplay.getScopeGroupId()}?filter=module%2F${assetType}%2Fid%20eq%20%27${assetId}%27`
      )
        .then((response) => response.json())
        .then((response) => {
          setCourse({
            audience: getPersonaStringList(response.items[0].audience),
            durationMinutes: convertMinutesToDuration(
              response.items[0].durationMinutes,
              "hours:minutes"
            ),
            firstLesson: response.items[0].firstLesson,
            id: response.items[0].id,
            level: response.items[0].level.name,
            title: response.items[0].title,
          });
        });
    }
  }, [assetId, assetType]);

  return parseInt(assetId) === course.firstLesson ? (
    <div className="course-top-intro-banner pt-4 pb-4">
      <ClayLayout.ContainerFluid view style={{ height: "100%" }}>
        <ClayLayout.Row justify="around" style={{ height: "100%" }}>
          <div className="d-flex flex-column h-100 justify-content-between w-100">
            <div className="align-items-start course-content-container d-flex flex-column">
              <div className="align-items-center banner-tag d-flex flex-row justify-content-center">
                <svg
                  fill=""
                  height="28"
                  viewBox="0 0 28 28"
                  width="28"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.58337 1.75V26.25"
                    stroke="#0B5FFF"
                    strokeWidth="1.55556"
                  />
                  <path
                    clipRule="evenodd"
                    d="M14 1.16667H5.83333C4.54467 1.16667 3.5 2.21133 3.5 3.5V24.5C3.5 25.7886 4.54467 26.8333 5.83333 26.8333H22.1667C23.4553 26.8333 24.5 25.7886 24.5 24.5V3.5C24.5 2.21133 23.4553 1.16667 22.1667 1.16667V2.33333C22.811 2.33333 23.3333 2.85567 23.3333 3.5V24.5C23.3333 25.1444 22.811 25.6667 22.1667 25.6667H5.83333C5.189 25.6667 4.66667 25.1444 4.66667 24.5V3.5C4.66667 2.85567 5.189 2.33333 5.83333 2.33333H14V1.16667ZM21.5833 2.33333H14.5833V1.16667H21.5833V2.33333Z"
                    fill="#0B5FFF"
                    fillRule="evenodd"
                  />
                  <path
                    d="M14.5834 0.583334V18.6667L18.0834 16.9167L21.5834 18.6667V1.16667C21.5834 0.844504 21.3222 0.583334 21 0.583334H14.5834Z"
                    fill="#0B5FFF"
                  />
                </svg>
                <h5>Course</h5>
              </div>
              <h3>{course.title}</h3>
            </div>
            <div className="align-items-center banner-tags d-flex">
              <div className="banner-tag card-tag__time">
                <p>{course.durationMinutes}</p>
              </div>
              <div className="banner-tag card-tag__persona">
                <p>{course.audience}</p>
              </div>
              <div
                className={`banner-tag card-tag__expertise-${
                  course.level ? course.level.toLowerCase() : ""
                }`}
              >
                <p>{course.level}</p>
              </div>
            </div>
          </div>
        </ClayLayout.Row>
      </ClayLayout.ContainerFluid>
    </div>
  ) : (
    <div className="course-top-banner">
      <ClayLayout.ContainerFluid view>
        <ClayLayout.Row justify="around">
          <div className="d-flex justify-content-between w-100">
            <div className="align-items-center course-content-container d-flex">
              <div className="align-items-center banner-tag d-flex flex-row justify-content-center">
                <svg
                  fill=""
                  height="28"
                  viewBox="0 0 28 28"
                  width="28"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.58337 1.75V26.25"
                    stroke="#0B5FFF"
                    strokeWidth="1.55556"
                  />
                  <path
                    clipRule="evenodd"
                    d="M14 1.16667H5.83333C4.54467 1.16667 3.5 2.21133 3.5 3.5V24.5C3.5 25.7886 4.54467 26.8333 5.83333 26.8333H22.1667C23.4553 26.8333 24.5 25.7886 24.5 24.5V3.5C24.5 2.21133 23.4553 1.16667 22.1667 1.16667V2.33333C22.811 2.33333 23.3333 2.85567 23.3333 3.5V24.5C23.3333 25.1444 22.811 25.6667 22.1667 25.6667H5.83333C5.189 25.6667 4.66667 25.1444 4.66667 24.5V3.5C4.66667 2.85567 5.189 2.33333 5.83333 2.33333H14V1.16667ZM21.5833 2.33333H14.5833V1.16667H21.5833V2.33333Z"
                    fill="#0B5FFF"
                    fillRule="evenodd"
                  />
                  <path
                    d="M14.5834 0.583334V18.6667L18.0834 16.9167L21.5834 18.6667V1.16667C21.5834 0.844504 21.3222 0.583334 21 0.583334H14.5834Z"
                    fill="#0B5FFF"
                  />
                </svg>
                <h5>Course</h5>
              </div>
              <h3>{course.title}</h3>
            </div>
            <div className="align-items-center banner-tags d-flex">
              <div className="banner-tag card-tag__time">
                <p>{course.durationMinutes}</p>
              </div>
              <div className="banner-tag card-tag__persona">
                <p>{course.audience}</p>
              </div>
              <div
                className={`banner-tag card-tag__expertise-${
                  course.level ? course.level.toLowerCase() : ""
                }`}
              >
                <p>{course.level}</p>
              </div>
            </div>
          </div>
        </ClayLayout.Row>
      </ClayLayout.ContainerFluid>
    </div>
  );
});

export default CourseBanner;
