/* global Liferay */

import { forwardRef, useEffect, useState } from "react";
import ClayIcon from "@clayui/icon";
import { getNextLesson, getPreviousLesson, isSignedIn } from "../utils/util";
import { useTranslation } from "react-i18next";
import { enrollToCourseAndCompleteAsset, getCourseByAssetTypeAndId } from "../services/course";
import {
  getUserEnrollmentByCourseId,
  updateCompletedAssets,
} from "../services/enrollment";

const LessonNavigation = forwardRef((props, ref) => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const { linkPrefix } = props;

  const [nextLesson, setNextLesson] = useState(null);
  const [prevLesson, setPrevLesson] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [userEnrollmentId, setUserEnrollmentId] = useState(null);
  const [completedAssetIds, setCompletedAssetsIds] = useState("");

  const currentLessonId = Liferay.ThemeDisplay.getLayoutURL().split("/").pop();

  useEffect(() => {
    Liferay.once("lessonNavigationUpdated", (event) => {
      setNextLesson(getNextLesson());
      setPrevLesson(getPreviousLesson());
    });
  }, []);

  useEffect(() => {
    const fetchCourseId = async () => {
      const response = await getCourseByAssetTypeAndId('lesson', currentLessonId);
      setCourseId(response);
    };

    fetchCourseId();
  }, [currentLessonId]);

  useEffect(() => {
    if (courseId) {
      const fetchUserEnrollment = async () => {
        const result = await getUserEnrollmentByCourseId(courseId);

        setUserEnrollmentId(result?.items?.length > 0 ? result.items[0].id : "");
        setCompletedAssetsIds(result?.items?.length > 0 ? result.items[0].completedAssetIds : []);
      };

      fetchUserEnrollment();
    }
  }, [courseId]);

  useEffect(() => {
    if (userEnrollmentId) {
      setIsEnrolled(true);
    }
  }, [userEnrollmentId, courseId]);

  const handleRegisterAndUpdateCompleteAssets = async () => {
    if (!isEnrolled) {
      await enrollToCourseAndCompleteAsset(courseId, currentLessonId);
    }
  };

  const navigateToLesson = (lessonId) => {
    const url = `${linkPrefix && linkPrefix.length > 0 ? linkPrefix : "/l/"}${
      lessonId
    }`;

    window.location.href = url;
  };

  const handleUpdateCompletedAssets = async () => {
    const isLessonCompleted = completedAssetIds?.includes(currentLessonId)
    
    if (isEnrolled && !isLessonCompleted) {
      await updateCompletedAssets(userEnrollmentId, completedAssetIds, currentLessonId);
    }
  };

  const handleClickCompleteButton = async () => {

    if (isEnrolled) {
      await handleUpdateCompletedAssets();
    } else {
      if (isSignedIn()) {
        await handleRegisterAndUpdateCompleteAssets();
      }
    }

    if (nextLesson) {
      navigateToLesson(nextLesson.id);
    } else {
      window.location.reload();
    }
  };

  return (
    <div>
      
        <div onClick={handleClickCompleteButton}>
          <div className="course-nav-bottom__banner d-flex">
            <div className="banner-options d-flex">
              <div className="banner-next-container mr-1">
                <ClayIcon symbol="check-circle" className="mr-2" />
                {t("mark-as-complete-and-continue")}
              </div>

              {nextLesson && nextLesson.id && (
                <>
                  <div className="banner-title">{nextLesson.title}</div>
                  <div className="banner-icon">
                    <ClayIcon symbol="order-arrow-right"></ClayIcon>
                  </div>
                </>
              )}
              
            </div>
          </div>
        </div>
    
      {prevLesson && (
        <div className="course-nav-bottom__menu d-flex">
          <div className="menu-previous-lesson d-flex" onClick={()=> {navigateToLesson(prevLesson.id)}}>
              <div className="previous-lesson-icon">
                <ClayIcon symbol="order-arrow-left"></ClayIcon>
              </div>
            <div className="previous-lesson-title">{prevLesson.title}</div>
          </div>
        </div>
      )}
    </div>
  );
});

export default LessonNavigation;