/*global Liferay*/
import {
  convertMinutesToDuration,
  fetchLiferayAsJSON,
  getCourseFirstLessonId,
} from "../utils/util";
import { forwardRef, useEffect, useState } from "react";
import "../index.css";

const LearningPathSteps = forwardRef((props, ref) => {
  const [learningPath, setLearningPath] = useState(null);
  const { learningPathId } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await fetchLiferayAsJSON(
          `/o/c/learningpaths/${learningPathId}/learningPathSteps`
        );

        const updatedSteps = await Promise.all(
          response1.items.map(async (step) => {
            const response2 = await fetchLiferayAsJSON(
              `/o/c/learningpathsteps/${step.id}/${
                step.type.key === "course" ? "stepCourses" : "externalMedia"
              }`
            );

            if (step.type.key !== "course") {
              return {
                ...step,
                totalDuration: response2.items[0].durationMinutes,
                url: response2.items[0].id,
              };
            }

            return {
              ...step,
              totalDuration: response2.items[0].durationMinutes,
              url: await getCourseFirstLessonId(response2.items[0].id),
            };
          })
        );
        setLearningPath(updatedSteps);
      } catch (error) {
        console.error("Error fetching learning path breakdown:", error);
      }
    };
    fetchData();
  }, [learningPathId]);

  return (
    <>
      {learningPath?.map((step, index) => {
        return (
          <div key={index}>
            <div
              className={`align-items-center card-banner d-flex ${step.type.key}`}
            >
              <span>Step {index + 1}</span>
            </div>
            <a href={`/l/${step.url}`} className="learning-path-step">
              <div
                className="col col-lg-12 col-sm-12 col-12 col-md-12 d-flex flex-column learn-education__card step-card"
                key={step.id}
              >
                <h3>{step.title}</h3>
                <div>{step.description}</div>
                <div className="banner-tags card-tag d-flex mt-3">
                  <div className="banner-tag card-tag__time">
                    <p>
                      {convertMinutesToDuration(
                        step.totalDuration,
                        "hours:minutes"
                      )}
                    </p>
                  </div>
                  <div>{step.completed}</div>
                </div>
              </div>
            </a>
          </div>
        );
      }) || <p>No learning path available.</p>}
    </>
  );
});

export default LearningPathSteps;
