import "../index.css";
import ClayLayout from "@clayui/layout";
import { getShortText} from "../utils/util";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLearningPathPage } from "../services/learning-path";

const getPersona = (personaArray) => {
  let persona = "";

  personaArray.forEach((personas) => {
    persona += personas.name + ", ";
  });
  
  return persona.slice(0, -2);
};

const LearningPathPage = () => {
  const [learningPaths, setLearningPaths] = useState(null);
  const {t, i18n: { changeLanguage, language },} = useTranslation();

  useEffect(() => {
    const fetchLearningPathPage = async () => {
      const result = await getLearningPathPage();
      setLearningPaths(result.items);
    };
    fetchLearningPathPage();
  }, []);

  return (
    <div>
      <div className="container-cards-page-2">
        {learningPaths && learningPaths.length > 0 && (
          <>
            {learningPaths.map((learningPath, index) => {
              return (
                <ClayLayout.Col key={index} className="card-learningPath-2">
                  <div className="tag-learningPath">
                    <span>{t("Learning Path")}</span>
                  </div>
                  <a
                    href={`/l/${learningPath.id}`}
                    id="learningPath-container-link"
                  >
                    <div className="learningPath-container d-flex learn-education__landing-card">
                      <div className="learningPath-content-card d-flex flex-column">
                        <h4 className="title">{learningPath.title}</h4>
                        <div className="description">
                          {getShortText(learningPath.description, 150)}
                        </div>
                        <div
                          className="d-flex information learn-education"
                          key={learningPath.id}
                        >
                          <div className="card-tag card-tag__persona">
                            <p></p>
                            <span>{getPersona(learningPath.persona)}</span>
                          </div>
                          <div
                            className={`card-tag card-tag__expertise-${learningPath.level.name.toLowerCase()}`}
                          >
                            <p>{learningPath.level.name}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </ClayLayout.Col>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default LearningPathPage;
